<template>
    <div class="flex flex-col gap-[4px]">
        <span class="block text-[#1E1E1E] font-semibold text-lg truncate">{{ data.position || data.degree }}</span>
        <span class="block text-[#1E1E1E] font-semibold text-base truncate">{{ data.company || data.school }}</span>
        <div class="flex gap-4 flex-col flex-">
            <div v-if="years > 0" class="flex items-center">
                <span class="block text-base text-[#7A7A7A] font-normal">{{ start_year }} - {{ end_year }}</span>
                <span class="block w-[6px] h-[6px] bg-[#C4C4C4] rounded-full mx-4"></span>
                <span class="block text-base text-[#7A7A7A] font-normal">{{ years }} yrs</span>
            </div>
            <div v-else class="flex items-center">
                <span class="block text-base text-[#7A7A7A] font-normal">{{ start_year }}</span>
            </div>
            <Tags :data="data.important_tags" />
        </div>
    </div>
</template>
<script>
import Tags from "@/components/profile/Tags.vue";

export default {
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    components: {Tags},
    computed: {
        start_year() {
            return new Date(this.data.start_date).getFullYear();
        },
        end_year() {
            return new Date(this.data.end_date).getFullYear();
        },
        years() {
            return this.end_year - this.start_year;
        }
    }
}
</script>